import type { Component } from 'react';

import { withAlignment } from '@skyscanner/backpack-web/bpk-component-icon';
import BpkLargeCarsIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/cars';
import BpkLargeFlightsIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/flight';
import BpkLargeHotelsIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/hotels';
import BpkLargeInformationCircleIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/information-circle';
import BpkLargeTripsIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/trips';
import BpkSmallChevronRightIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/chevron-right';
import BpkLink from '@skyscanner/backpack-web/bpk-component-link';
import { lineHeightBase, iconSizeSm } from 'bpk-tokens/tokens/base.es6';

import {
  ACTION_TYPE,
  COMPONENT_ACTION,
  COMPONENT_NAME,
} from '../../../constants';
import logMiniEventHelper from '../../../mini-event/logMiniEventHelper';

import type { CrossLinkItem } from 'common-types/types/components';

import STYLES from './CrossLink.module.scss';

type CategoryIconMap = {
  [key: string]: Component;
};

const iconsForCategory: CategoryIconMap = {
  'car-hire': BpkLargeCarsIcon,
  hotels: BpkLargeHotelsIcon,
  flights: BpkLargeFlightsIcon,
  flightsArrivalsDepartures: BpkLargeFlightsIcon,
  destinations: BpkLargeTripsIcon,
};

const alignedIconForCategory = (category: string) =>
  withAlignment(
    iconsForCategory[category]
      ? iconsForCategory[category]
      : BpkLargeInformationCircleIcon,
    lineHeightBase,
    iconSizeSm,
  );

const AlignedChevronRightIcon = withAlignment(
  BpkSmallChevronRightIcon,
  lineHeightBase,
  iconSizeSm,
);

const handleClick = () => {
  logMiniEventHelper({
    action_type: ACTION_TYPE.COMPONENT_CLICKED,
    component_name: COMPONENT_NAME.SEO_CROSS_LINK,
    component_action: COMPONENT_ACTION.SEO_CROSS_LINK.SEO_CROSS_LINK_CLICKED,
  });
};

const CrossLink = ({ category, label, url }: CrossLinkItem) => {
  const IconForCategory = alignedIconForCategory(category);
  return (
    <li className={STYLES.CrossLink}>
      <BpkLink
        href={url}
        blank
        rel="noopener noreferrer"
        onClick={() => handleClick()}
        className={STYLES.linkListItem}
      >
        <IconForCategory className={STYLES.CrossLink__icon} />
        <span className={STYLES.CrossLink__text}>{label}</span>
        <AlignedChevronRightIcon className={STYLES.CrossLink__chevron} />
      </BpkLink>
    </li>
  );
};

export default CrossLink;
