import loadable from '@loadable/component';

import BpkSectionHeader from '@skyscanner/backpack-web/bpk-component-section-header';

import { ACTION_TYPE, COMPONENT_NAME } from '../../../constants';
import logMiniEventHelper from '../../../mini-event/logMiniEventHelper';
import withErrorBoundary from '../../ErrorBoundary';
import CrossLink from '../CrossLink/CrossLink';

import type {
  SeoCrossLinksProps,
  CrossLinkItem,
} from 'common-types/types/components';

import STYLES from './CrossLinks.module.scss';

const IntersectionObserverWrapper = loadable(
  () => import('../../IntersectionObserverWrapper/IntersectionObserverWrapper'),
);

const CrossLinks = (props: SeoCrossLinksProps) => (
  <div className={STYLES.CrossLinks} id="seo-cross-links-root">
    {props.title && props.links && props.links.length > 0 && (
      <BpkSectionHeader title={props.title} description={props.subtitle} />
    )}

    <ul className={STYLES.CrossLinks__ul}>
      {props.links.map((link: CrossLinkItem) => (
        <CrossLink key={Object.values(link).join('-')} {...link} />
      ))}
    </ul>
  </div>
);

const CrossLinksWrapper = (props: SeoCrossLinksProps) => (
  <IntersectionObserverWrapper
    onElementSeen={() =>
      logMiniEventHelper({
        action_type: ACTION_TYPE.COMPONENT_VISIBLE,
        component_name: COMPONENT_NAME.SEO_CROSS_LINK,
      })
    }
  >
    <CrossLinks {...props} />
  </IntersectionObserverWrapper>
);

const CrossLinksWithErrorBoundary = withErrorBoundary(
  CrossLinksWrapper,
  'cross-links-component',
);

export default CrossLinksWithErrorBoundary;
